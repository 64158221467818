import React from "react"
import styled from 'styled-components'

import { BreakPoint } from "../variables/"
import { BoxPadding } from '../styles/'

const LogoBlockWrapper = styled.section`
	display: flex;
	align-items: center;
	background: ${props => props.bgColour ? props.bgColour : "#eee"};
	>div {
		width: 100%;
	}
	@media ${BreakPoint.med} { 
		min-height: 25vw;
	}
`
const ImageWrapper = styled.div`
	>img {
		display: block;
		width: 100%;
		max-width: 250px;
		max-height: 200px;
		margin: 20px auto;
		@media ${BreakPoint.med} { 
			max-width: 400px;
			max-height: 250px;
		}
	}
`

export const LogoBlock = (props) => {

	return (
		<LogoBlockWrapper bgColour={ props.colour }>
			<BoxPadding>
				<ImageWrapper>
					<img alt={ props.alt } src={ props.src } />
				</ImageWrapper>
			</BoxPadding>
		</LogoBlockWrapper>
	)
}