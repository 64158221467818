import React, {useEffect}  from "react"
import styled from 'styled-components'

import { Image, Overlay } from './'
import { BreakPoint } from '../variables/'

const ImageBoxWrapper = styled.div`
	position: relative;
	aspect-ratio: ${props => props.ratio ? props.ratio : '3/2'};
`
const ImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	>div {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit:cover;
		@media ${BreakPoint.med} { 
			position: ${props => props.fitText ? "absolute!important" : "absolute"};
		}
	}
`

export const ImageBox = ({ alt, imageData, overlay, colour, fitText, ratio }) => {

	return (
		<ImageBoxWrapper ratio={ ratio }>
			{ overlay &&
				<Overlay colour={colour}/>
			}
			<ImageWrapper fitText={fitText}>
				<Image imageData={imageData} alt={alt ?? 'image'}/>
			</ImageWrapper>
		</ImageBoxWrapper>
	)
}