import { BreakPoint } from './index'

const size = {
	xxsml: { mob:`1.2rem`, desk:`1.4rem` },
	xsml: { mob:`1.4rem`, desk:`1.6rem` },
	sml: { mob:`1.6rem`, desk:`1.8rem` },
	med: { mob:`1.8rem`, desk:`2.5rem` },
	lrg: { mob:`2.5rem`, desk:`4rem` },
	xlrg: { mob:`3rem`, desk:`5rem` },
	xxlrg: { mob:`5rem`, desk:`8rem` },
}
export const FontSize = {
	xxsml: `
		font-size: ${size.xxsml.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.xxsml.desk};
		}`,
	xsml: `
		font-size: ${size.xsml.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.xsml.desk};
		}`,
	sml: `
		font-size: ${size.sml.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.sml.desk};
		}`,
	med: `
		font-size: ${size.med.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.med.desk};
		}`,
	lrg: `
		font-size: ${size.lrg.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.lrg.desk};
		}`,
	xlrg: `
		font-size: ${size.xlrg.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.xlrg.desk};
		}`,
	xxlrg: `
		font-size: ${size.xxlrg.mob};
		@media ${BreakPoint.med} {
			font-size: ${size.xxlrg.desk};
		}`,
}
