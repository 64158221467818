import React from "react"

import { ImageBox, TextBox, VideoBox } from "../partials/"
import { Split } from "./"

export const MediaSplit = ({ colour, image, overlay, text, video }) => {
	
	return (
		<Split>
			{ video ?
				<VideoBox
					ratio="3/2"
					imageData={ image }
					video={ video }
					colour={ colour }
					overlay={ overlay }/>
			:
				<ImageBox
					ratio="3/2"
					imageData={ image }
					colour={ colour }
					overlay={ overlay }/>
			}
			<TextBox
				colour={colour}
				text={ text }/>	
		</Split>
	)
}