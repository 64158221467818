import React from "react"
import styled from 'styled-components'

import { FontSize } from "../variables/"
import { BoxPadding } from '../styles/'

const InlineListWrapper = styled.section`
	background: ${props => props.bgColour ? props.bgColour : "#eee"};
	color: ${props => props.colour ? props.colour : "#fff"};
`
const Title = styled.h3`
	${FontSize.med}
	color: ${props => props.colour ? props.colour : "#fff"};
	margin-right: 15px;
	display: inline-block;
`
const List = styled.ul`
	list-style: none;
	padding: 0;
	display: inline-block;
	li {
		${FontSize.med}
		display: inline-block;
	}
`

export const InlineList = (props) => {

	return (
		<InlineListWrapper bgColour={ props.highlight }>
			<BoxPadding>
				<Title colour={ props.lowlight }>Scope &gt;</Title>
				<List>
					{ props.list.map((item, i, arr) =>
						<li key={item}>{item}
							{i !== arr.length - 1 &&
								<span>&bull;</span>
							}
						</li>
					)}
				</List>
			</BoxPadding>
		</InlineListWrapper>
	)
}