import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha"

import { CheckboxField, InputField, TextareaField } from '../partials/'
import { BoxPadding, Button, Element, Field, TextContent } from '../styles/'
import { BreakPoint } from '../variables/'

const FormWrapper = styled.div`
	width: 100%;
	@media ${BreakPoint.med} {
		width: 50%;
	}
`
const ReCAPTCHAWrapper = styled(ReCAPTCHA)`
	margin-bottom: 20px;
`

export const Form = ({ bg, theme }) => {

	const [formValues, setFormValues] = useState({name: '', email: '', message: '', consentEmail: false, consentPhone: false, consentPost: false})

	const handleOptionChange = (evt) => {
		evt.persist()
		setFormValues(currentValues => ({
			...currentValues,
			[evt.target.name]: evt.target.value
		}))
	}

	const handleCheckChange = (evt) => {
		evt.persist()
		setFormValues(currentValues => ({
			...currentValues,
			[evt.target.name]: evt.target.checked ? true : false
		}))

	}

	useEffect(() => {

		console.log(formValues)

	})

	return (
		<FormWrapper>
			<BoxPadding>
				<form
					name="Enquiry"
					method="post"
					action="/contact/thanks"
					data-netlify="true"
					data-netlify-recaptcha="true"
					data-netlify-honeypot="bot-field">
					<input type="hidden" name="form-name" value="Enquiry" />
					<p hidden>
						<label>Don’t fill this out: <input name="bot-field"/></label>
					</p>
					<Field>
						<label>
							<p>Name*</p>
							<InputField
								theme={ theme }
								bg={ bg }
								type="text"
								name="name"
								value={ formValues.name }
								onChange={ handleOptionChange }
								required/>
						</label>
					</Field>
					<Field>
						<label>
							<p>Email*</p>
							<InputField
								theme={ theme }
								bg={ bg }
								type="email"
								name="email"
								value={ formValues.email }
								onChange={ handleOptionChange }
								required/>
						</label>
					</Field>
					<Field>
						<label>
							<p>Message*</p>
							<TextareaField
								theme={ theme }
								bg={ bg }
								name="message"
								value={ formValues.message }
								onChange={ handleOptionChange }
								required/>
						</label>
					</Field>

					<Element>
						<TextContent>
							<p>From time to time, we’d like to keep in touch with relevant products, services, offers we think you’ll be interested in.</p>
							<p>If you’re ok with this please let us know by indicating how you would like us to contact you. You can change your mind and withdraw your consent at any time - see our <Link to="/privacy-policy" role="link">privacy policy</Link> to find out more.</p>
							<p>Yes please, keep me up to date:</p>
							<CheckboxField
								theme={ theme }
								name={ 'consentEmail' }
								value={ formValues.consentEmail }
								label={ "OK to Email" }
								onChange={ handleCheckChange }/>
							<CheckboxField
								theme={ theme }
								name={ 'consentPhone' }
								value={ formValues.consentPhone }
								label={ "OK to Phone" }
								onChange={ handleCheckChange }/>
							<CheckboxField
								theme={ theme }
								name={ 'consentPost' }
								value={ formValues.consentPost }
								label={ "OK to Post" }
								onChange={ handleCheckChange }/>
						</TextContent>
					</Element>
					<ReCAPTCHAWrapper
						theme={'dark'}
						sitekey="6LfMeEsUAAAAAI9CkISuz9256PLx3Vf4_Bv6hU-q"/>

					<Button bg={ theme } colour={ '#fff' } type="submit">Send</Button>
				</form>
			</BoxPadding>
		</FormWrapper>
	)
}