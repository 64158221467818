import React, { useState, useEffect }  from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ClientSliderRow } from "../../slices"

export const ClientSlider = () => {
	
	const data = useStaticQuery(graphql`
		query {
			allContentfulClient(sort: {updatedAt: DESC}) {
				edges {
					node {
						title
						slug
						sector
						blockOneImage { 
							gatsbyImageData
						}
						overview
					}
				}
			}
			
		}
	`)
	
	const [lrgTile, setLrgTile] = useState(6);
	const [medTile, setMedTile] = useState(12);

	function defineTiles() {
		if(window.innerWidth <= 560) {
// 			console.log('is mobile')
			setLrgTile(2)
			setMedTile(4)
		} else if(window.innerWidth > 560 & window.innerWidth <= 992) {
// 			console.log('is tab')
			setLrgTile(4)
			setMedTile(8)
		}  else {
// 			console.log('is desktop')
			setLrgTile(6)
			setMedTile(12)
		}
	}
	
	useEffect(() => {
		defineTiles()
		window.addEventListener("resize", defineTiles)

	})
	
	return (
		<section>
			<ClientSliderRow 
				id={'scroller-lrg'}
				data={data.allContentfulClient.edges}
				tiles={lrgTile}
				speed={12}
				shift={0}
				overlay={true}/>
			<ClientSliderRow 
				id={'scroller-med'}
				data={data.allContentfulClient.edges}
				tiles={medTile}
				speed={6}
				shift={lrgTile}/>
		</section>
	)
}