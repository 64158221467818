import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useSpring, animated } from 'react-spring'

import { Logo, Nav } from "./"
import { ClientSlider } from "../slices"

export const Page = ({ children, colourPrimary, colourSecondary, themeName }) => {

	const [openNav, setOpenNav] = useState(false)
	
	const fadeIn = useSpring({opacity: 1, from: {opacity: 0}})
	
	useEffect(() => {	
		if(window.location.href.indexOf("#nav") > -1) {
			setOpenNav(true)
		}
	}, [])

	return (

		<animated.div id="page" style={ fadeIn } className={`theme--${ themeName }`}>
			<Logo/>
			<Nav 
				open={ openNav }
				primaryColour={ colourPrimary } 
				secondaryColour={ colourSecondary }/>
			<main>
				{ children }
			</main>
			<ClientSlider/>
		</animated.div>

	)
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}
