import React from "react"
import styled from 'styled-components'

import { FontSize, Black } from "../../variables"

const Input = styled.input`
	${FontSize.sml}
	width: 100%;
	display: block;
	outline: none;
	border: none;
	border-bottom: 3px solid ${Black.Primary};
	border-color: ${props => props.theme ? props.theme : Black.Primary};
	background: ${props => props.bg ? props.bg : "transparent"};
	color: ${Black.Primary};
	padding: 10px;
	font-family: inherit;
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		font-family: inherit;
	  -webkit-text-fill-color: ${Black.Primary};
	  -webkit-box-shadow: 0 0 0px 1000px ${props => props.bg ? props.bg : "transparent"} inset;
	  transition: background-color 5000s ease-in-out 0s;
	}
	&[disabled] {
		pointer-events:none;
	}
`
export const InputField = ({ bg, name, placeholder, onChange, options, required, theme, type, value }) => {
	return (

		<Input
			bg={ bg }
			theme={ theme }
			value={ value }
			placeholder={ placeholder }
			name={ name }
			type={ type }
			onChange={ onChange }
			required={ required }/>

	)
}
