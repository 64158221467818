import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { BreakPoint } from "../variables/"

import RingSVG from "../../images/logos/ring.svg"

const LogoPos = `
	position: fixed;
	z-index: 1;
	right: 0;`

const LogoText = styled.span`
	${LogoPos}

	top: 20px;
	font-size: 4rem;
	color: #1d1d1d;
	writing-mode: tb-rl;
	transform: rotate(-180deg);
	margin: 10px 0;
	display: none;
	mix-blend-mode: difference;
	@media ${BreakPoint.med} { 
		display: inline-block;
	}
`
const LogoRing = styled(RingSVG)`
	${LogoPos}
	width: 16px;
	z-index: 100;
	margin: 0 10px;
	top: 10px;
	font-size: 1.6rem;
	&:before {
		color: #000;
	}
	@media ${BreakPoint.med} { 
		&:before {
			color: inherit;
		}
	}
`

export const Logo = () => (
	<Link to={`/`} title={'Click to visit the Satellite homepage'}>
		<LogoText>Satellite</LogoText>
		<LogoRing/>
	</Link>
)