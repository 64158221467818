import React from "react"
import styled from 'styled-components'

import { BreakPoint } from '../variables/'

const Ring = styled.span`
	position: absolute;
	z-index: 4;
	width: 100%;
	height: 100%;
	&:after {
		position: absolute;
		top: calc(50% - 75px);
	    left: calc(50% - 75px);
	    z-index: 1000;
		width: 50px;
	    height: 50px;
	    content: " ";
	    border: 50px solid #fff;
	    border-radius: 50%;
	    box-sizing: content-box;
	    pointer-events: none;
	    @media ${BreakPoint.sml} {
			top: calc(50% - 105px);
			left: calc(50% - 105px);
			width: 70px;
			height: 70px;
			border-width: 70px;
		}
	}
`
const Background = styled.span`
	position: absolute;
	z-index: 3;
	width: 100%;
	height: 100%;
	background: green;
	mix-blend-mode: multiply;
	@media ${BreakPoint.ie} { 
		opacity: 0.2;
	}
`

export const Overlay = ({ colour }) => (
	<>
		<Ring/>
		{colour &&
				<Background style={{ backgroundColor: colour }}/>
		}
	</>
)