import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const Image = ({ imageData, alt }) => {

	const image = getImage(imageData)

	return (
		<GatsbyImage image={image} alt={alt} />
	)
}
