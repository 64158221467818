import styled from 'styled-components'
import { darken } from 'polished'
import { FontSize } from '../variables/'

export const Button = styled.button`
	display: block;
	${FontSize.sml}
	font-weight: bold;
	padding: 10px 20px;
	border: none;
	border-radius: 3px;
	background: ${props => props.bg ? props.bg : "#fff"};
	color: ${props => props.colour ? props.colour : "#000"};
	cursor: pointer;
	&:hover {	
		background: ${props => props.bg ? darken(0.05, props.bg) : "#000"};
	}
`