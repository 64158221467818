import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import { TextBox } from '../partials/'
import { Colours } from "../variables/"

const ClosingWrapper = styled.section`
	background: ${props => props.bg ? props.bg : "#eee"};
	color: ${props => props.colour ? props.colour : "#000"};
`

export const Closing = () => {

	const data = useStaticQuery(graphql`
		query {
			contentfulGlobal ( slug: { eq: "global-content" } ) {
				closing { raw }
			}
			
		}
	`)

	const BgColour = Colours.filter(colour => ('black').includes(colour.slug))

	return (
		<ClosingWrapper bg={ BgColour[0].primary } colour={ BgColour[0].secondary }>
			<TextBox
				align="center"
				text={ data.contentfulGlobal.closing }/>
		</ClosingWrapper>
	)
}