import React from "react"
import styled from 'styled-components'

import { Overlay, Image } from "./"
import { BreakPoint } from '../variables/'

const VideoBoxWrapper = styled.div`
	position: relative;
	aspect-ratio: ${props => props.ratio ? props.ratio : 'auto'};
`
const Media = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	>video, >div {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit:cover;
		@media ${BreakPoint.med} { 
			position: ${props => props.fitText ? "absolute!important" : "absolute"};
		}
	}
`
export const VideoBox = ({ overlay, colour, fitText, image, video, ratio }) => (
	<VideoBoxWrapper ratio={ ratio }>
		{ overlay &&
			<Overlay colour={ colour }/>
		}
		<Media fitText={ fitText }>
			{ image &&
				<Image imageData={image}/>
			}
			<video loop muted autoPlay playsInline>
				<source src={ video } type="video/mp4" />
			</video>
		</Media>
	</VideoBoxWrapper>
)