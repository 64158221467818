import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import { TextBox } from '../../partials/'
import { BoxPadding } from '../../styles/'
import { Colours } from "../../variables/"
import { TeamTile } from '../'

const TeamTilesWrapper = styled.section`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
`
const Last = styled.div`
	background: ${props => props.bg ? props.bg : "#eee"};
	color: #fff;
	width:100%;
	flex: 1;
	align-self: stretch;
	display: flex;
	align-content: flex-end;
	a, span {
		color: #000;
	}
`

export const TeamTileGroup = (props) => {

	const data = useStaticQuery(graphql`
		query {
			allContentfulTeam {
				edges {
					node {
						name
						description { description }
						image { gatsbyImageData }
					}
				}
			}
			contentfulGlobal ( slug: { eq: "global-content" } ) {
				hiring { raw }
			}
		}
	`)

	const LastColour = Colours.filter(colour => ('mandarin').includes(colour.slug));

	return (
		<TeamTilesWrapper>
			{data.allContentfulTeam.edges.map(member => {
			    const memberData = member.node
			    
			    return ( 
				    <TeamTile 
				    	key={memberData.name}
				    	data={ memberData }
				    	bg={ props.bg }/>
			    )
			})}
			<Last bg={LastColour[0].primary}>
				<TextBox
					text={ data.contentfulGlobal.hiring }/>
			</Last>
		</TeamTilesWrapper>
	)
}