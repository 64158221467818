import styled from 'styled-components'

import { FontSize } from "../variables"

export const Field = styled.div`
	width: 100%;
	margin-bottom: 20px;
	label {
		${FontSize.sml}
		p {
			margin-bottom: 10px;
		}
	}
`