import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import { Ring } from "../partials/"
import { BreakPoint } from '../variables'
import { BoxPadding, TextContent } from "../styles/"
import { ContentfulRichText } from "./"

const locale = 'en-GB'

const Align = styled.div`
	width: 100%;
	background: ${props => props.bg ? props.bg.primary : "none"};
	color: ${props => props.bg ? props.bg.secondary : "inherit"};
	text-align: ${props => props.align ? props.align : "left"};
	@media ${BreakPoint.med} {
		align-self: ${props => props.align ? props.align : "flex-end"};
	}
`
const Row = styled(TextContent)`
	column-gap: 60px;
	max-width: ${props => props.restrict ? "600px" : "none"};
	@media ${BreakPoint.med} {
		column-count: ${props => props.columns ? props.columns : "1"};
	}
`

const TextWrapper = ( props ) => (
	<Align align={ props.align } bg={ props.bg }>
		{ props.noPadding ?
			<TextContent>
				{props.children}
			</TextContent>
		:
			<BoxPadding>

				<TextContent>
					{props.children}
				</TextContent>

			</BoxPadding>
		}
	</Align>
)

export const TextBox = ({ text, align, bg, noPadding, title, titleTag, className, children, restrict, columns, staticText, link, colour }) => (

	<TextWrapper align={ align } bg={ bg } noPadding={noPadding}>

		{ title &&
			React.createElement(
				titleTag,
				className,
				title
			)
		}
		<Row restrict={ restrict } columns={ columns }>
			{ staticText &&
				<>
					<p>{ staticText }</p>

					{ link &&

						<Link to={link.url}
							role="link"
							tabIndex={0}>{link.text}</Link>

					}
				</>
			}
			{ children &&
				<>
					{children}
				</>
			}
			{ text &&
				<>
					<ContentfulRichText raw={ text } colour={colour}/>
					{ link &&

						<Link to={link.url}
							role="link"
							tabIndex={0}>{link.text}</Link>

					}
				</>
			}
		</Row>

	</TextWrapper>
)
