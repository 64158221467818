import React, { useEffect, useState } from "react"
import styled from 'styled-components'

import { FontSize, Black, TickIcon } from "../../variables"

const Wrapper = styled.label`
	display: flex;
	margin-bottom: 10px!important;
	&:last-child {
		margin-bottom: 0px!important;
	}
`
const Radio = styled.div`
	position: relative;
	width: 2rem;
	height: 2rem;
	input {
		position: absolute;
		width: auto;
		z-index: -1;
		opacity: 0;
		&:checked {
			~ div {
				svg {
					opacity: 1;
				}
			}
		}
	}
	div {
		position: absolute;
		overflow: hidden;
		top: 0;
		left: 0;
		height: 2rem;
		width: 2rem;
		border: 2px solid ${props => props.theme ? props.theme : Black.primary};
		border-radius: 2px;
		pointer-events: none;
		color: ${Black.primary};
		svg {
			opacity: 0;
			position: absolute;
			overflow: hidden;
			top: 0.2rem;
			left: 0.2rem;
			font-size: 1.2rem;
		}
	}
`
const Text = styled.span`
	${FontSize.sml}
	margin-left: 10px!important;
	flex: 1;
`

export const CheckboxField = ({ name, value, label, questionId, onChange, required, theme  }) => {
	
	useEffect(() => {

		console.log(theme)
		
	})
	
	return (
		<Wrapper>
			<Radio theme={ theme }>
				<input 
					type="checkbox"
					name={ name }
					value={ value }
					onChange={ onChange }
					required={ required }/>
				<div><TickIcon/></div>
			</Radio>
			<Text>{ label }</Text>
		</Wrapper>
	)
}