import React, {useEffect} from "react"
import styled from 'styled-components'

import RingSVG from "../../images/logos/ring.svg"

const RingIcon = styled(RingSVG)`
    display: inline-block;
	width: ${props => props.width ? props.width : "10px"};
    vertical-align: baseline;
    margin-left: 3px;
    path {
        fill: ${props => props.colour ? props.colour : "inherit"};
    }
`

export const Ring = ({ colour, width }) => {
    useEffect(() => {
        console.log(colour)
    })
	return (
		<RingIcon colour={ colour } width={width}/>
    )
}
