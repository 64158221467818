import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import { Colours, FontSize } from "../variables/"
import { BoxPadding } from '../styles/'
import { TextBox } from '../partials/'

const PaginateWrapper = styled(BoxPadding)`
	background: ${props => props.bg ? props.bg : "#eee"};
	color: ${props => props.colour ? props.colour : "#000"};
`
const PaginateLinks = styled.div`
	display: flex;
	a {
		flex: 1;
		margin-top: 20px;
		${FontSize.sml}
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
		&:last-child {
			text-align: right;
		}
	}
`

export const PaginateClients = (props) => {

	const data = useStaticQuery(graphql`
		query {
			contentfulGlobal ( slug: { eq: "global-content" } ) {
				paginate { raw }
			}
			
		}
	`)

	const BgColour = Colours.filter(colour => ('black').includes(colour.slug))

	return (
		<PaginateWrapper bg={ BgColour[0].primary } colour={ BgColour[0].secondary }>
			<TextBox
				noPadding
				text={ data.contentfulGlobal.paginate }/>
			<PaginateLinks>
				<Link to={ props.prev }>&lt; Previous project</Link>
				<Link to={ props.next }>Next project &gt;</Link>
			</PaginateLinks>
		</PaginateWrapper>
	)
}