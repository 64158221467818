import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'

import { TextBox } from '../partials/'
import { BoxPadding } from '../styles/'

const AffiliatesWrapper = styled.section`
	background: ${props => props.bg ? props.bg : "#eee"};
	color: ${props => props.colour ? props.colour : "#fff"};
`
const AffiliateLogos = styled.div`
	margin-top: 40px;
	div {
		display: inline-block;
		width: 100%;
		max-width: 200px;
		margin-right: 40px;
		margin-bottom: 20px;
		&:last-child {
			margin-right: 0;
		}
	}
`

export const Affiliates = (props) => {

	const data = useStaticQuery(graphql`
		query {
			contentfulGlobal ( slug: { eq: "global-content" } ) {
				affiliateText { raw }
				affiliate {
					affiliateLogos {
						title
						file {
							url
						}
						link
					}
				}
			}
		}
	`)

	const global = data.contentfulGlobal

	return (
		<AffiliatesWrapper bg={ props.bg } colour={'#fff'}>
			<BoxPadding>
				<TextBox noPadding
					text={ global.affiliateText }/>

				<AffiliateLogos>
					{global.affiliate.affiliateLogos.map(image => {
					    return ( 
						    <div key={ image.title }>
							    <a href={ image.link } target="_blank" rel="noreferrer noopener">
									<img alt={ image.title } src={ image.file.url } />
							    </a>
							</div>
					    )
					})}
				</AffiliateLogos>
			</BoxPadding>
		
		</AffiliatesWrapper>
	)
}