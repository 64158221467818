import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { Link } from "gatsby"

//components
import { BreakPoint, Colours, FontSize, MenuIcon, MenuCloseIcon } from "../variables/"
import { Logo } from "./"

const NavWrapper = styled.nav`
	&:after {
		content: "";
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 49px;
		background: transparentize(#fff, 0.8);
		z-index: 1;
		@media ${BreakPoint.med} {
			background: none;
		}
	}
`
const Trigger = styled.span`
	font-size: 2.5rem;
	padding: 12px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	color: #fff;
	background: #1d1d1d;
	@media ${BreakPoint.med} {
		font-size: 3rem;
		padding: 15px;
	}
`
const NavSlide = styled(animated.div)`
	background: #eee;
	color: #333;
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	transform: translate3d(0,-100vh,0);
	-webkit-transition: top 0.5s;
	transition: top 0.5s;
    animation-fill-mode: forwards;
`
const NavLogo = styled(Logo)`
	display: none;
	@media ${BreakPoint.med} {
		display: block;
	}
`
const NavContent = styled.div`
	height: 100%;
	padding: 50px 20px 20px;
	display: flex;
	flex-direction: column;
	justify-content:flex-end;
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		margin: 0;
		padding-right: 10px;
		a {
			color: inherit;
			text-decoration: none;
			&.active, &:hover {
				text-decoration: underline;
			}
		}
	}
	@media ${BreakPoint.med} {
		padding: 80px;
	}
`
const NavLinks = styled.div`
	overflow-y: auto;
`
const SecondaryLinks = styled.div`
	ul {
		margin-bottom: 10px;
		&:last-child {
			margin: 0;
		}
	}
	li {
		display: inline-block;
		margin-bottom: 10px;
		&:last-child {
			margin: 0;
		}
		&:after {
			content: "|";
			padding-left: 10px;
		}
		&:last-child {
			&:after {
				display: none;
			}
		}
	}
`
const LargeLinks = styled.ul`
	li {
		${FontSize.xxlrg}
		margin-bottom: 20px;
	}
`
const MediumLinks = styled.ul`
	li {
		${FontSize.med}
	}
`
const SmallLinks = styled.ul`
	li {
		${FontSize.sml}
		color: #fff;
	}
`

export const Nav = ({primaryColour, secondaryColour, open}) => {

	const data = useStaticQuery(graphql`
		query {
			work: contentfulPage ( slug: { eq: "index" } ) {
				theme
			}
			about: contentfulPage ( slug: { eq: "about" } ) {
				theme
			}
			contact: contentfulPage ( slug: { eq: "contact" } ) {
				theme
			}
			blog: contentfulPage ( slug: { eq: "blog" } ) {
				theme
			}
			services: contentfulPageEntry ( slug: { eq: "services" } ) {
				theme {
					slug
					primaryHex
					secondaryHex
				}
			}
		}
	`)

	let workColour = Colours.filter(theme => data.work.theme.includes(theme.slug))
	let aboutColour = Colours.filter(theme => data.about.theme.includes(theme.slug))
	let contactColour = Colours.filter(theme => data.contact.theme.includes(theme.slug))
	let blogColour = Colours.filter(theme => data.blog.theme.includes(theme.slug))
	let servicesColour = data.services.theme

	const [initial, setInitial] = useState(false)
	const [isToggled, setToggle] = useState(false)
	const [hovered, setHovered] = useState(null)

	const colourPrimary = primaryColour || '#eee'
	const colourSecondary = secondaryColour || '#000'

	const transitionOptions = [
		'translate3d(0vw,-100vh,0)',
		'translate3d(0vw,100vh,0)',
		'translate3d(-100vw,0vh,0)',
		'translate3d(100vw,0vh,0)',
	]
	const randomTransition = Math.floor(Math.random()*transitionOptions.length)
	const bgAnimate = useSpring({
		background:
			hovered === 'work' ? workColour[0].primary :
			hovered === 'about' ? aboutColour[0].primary :
			hovered === 'services' ? servicesColour.primaryHex :
			hovered === 'contact' ? contactColour[0].primary :
			hovered === 'blog' ? blogColour[0].primary : colourPrimary,
		color:
			hovered === 'work' ? workColour[0].secondary :
			hovered === 'about' ? aboutColour[0].secondary :
			hovered === 'services' ? servicesColour.secondaryHex :
			hovered === 'contact' ? contactColour[0].secondary :
			hovered === 'blog' ? blogColour[0].secondary : colourSecondary,
		transform:
			isToggled ? 'translate3d(0vw,0vh,0)' : transitionOptions[randomTransition]
	})

	useEffect(() => {
		if(!initial && open) {
			setToggle(true)
			setInitial(true)
		}
	})

	return (
		<NavWrapper>
			<Trigger
				role="link"
				tabIndex={0}
				onClick={() => setToggle(!isToggled)}
				onKeyDown={() => setToggle(!isToggled)}>
				{isToggled ?
					<MenuCloseIcon/>
				:
					<MenuIcon/>
				}
				
			</Trigger>

			<NavSlide style={ bgAnimate }>
				<NavLogo colour={ '#fff' }/>
				<NavContent>
					<NavLinks>
						<LargeLinks>
							<li>
								<Link to="/" activeClassName={'active'}
									role="link"
									tabIndex={0}
									onMouseOver={() => setHovered('work')}
							    	onFocus={() => setHovered('work')}
							    	onMouseOut={() => setHovered(null)}
							    	onBlur={() => setHovered(null)}>Home</Link>
							</li>
							<li>
								<Link to="/about" activeClassName={'active'}
									role="link"
									tabIndex={0}
									onMouseOver={() => setHovered('about')}
							    	onFocus={() => setHovered('about')}
							    	onMouseOut={() => setHovered(null)}
							    	onBlur={() => setHovered(null)}>About</Link>
							</li>
							<li>
								<Link to="/services" activeClassName={'active'}
									role="link"
									tabIndex={0}
									onMouseOver={() => setHovered('services')}
							    	onFocus={() => setHovered('services')}
							    	onMouseOut={() => setHovered(null)}
							    	onBlur={() => setHovered(null)}>Services</Link>
							</li>
							<li>
								<Link to="/contact" activeClassName={'active'}
									role="link"
									tabIndex={0}
									onMouseOver={() => setHovered('contact')}
							    	onFocus={() => setHovered('contact')}
							    	onMouseOut={() => setHovered(null)}
							    	onBlur={() => setHovered(null)}>Contact</Link>
							</li>

						</LargeLinks>
						<SecondaryLinks>
							<MediumLinks>
								<li>
									<a href="mailto:hello@satellitecreative.com">Email us</a>
								</li>
								<li>
									<a href="tel:01442827768">Call us</a>
								</li>
							</MediumLinks>
							<SmallLinks>
								<li>
									<a href="https://twitter.com/satcreative" target="_blank" rel="noopener noreferrer">Twitter</a>
								</li>
								<li>
									<a href="https://www.instagram.com/satellitecreative/" target="_blank" rel="noopener noreferrer">Instagram</a>
								</li>
							</SmallLinks>
							<SmallLinks>
								<li>
									<Link to="/terms-conditions" activeClassName={'active'}>Terms & conditions</Link>
								</li>
								<li>
									<Link to="/privacy-policy" activeClassName={'active'}>Privacy policy</Link>
								</li>
								<li>
									<Link to="/corporate-responsibility" activeClassName={'active'}>Corporate Responsibility</Link>
								</li>
								<li>Registered in England #03315229</li>
							</SmallLinks>
						</SecondaryLinks>
					</NavLinks>
				</NavContent>
			</NavSlide>

		</NavWrapper>
	)
}
