import React from "react"
import styled from 'styled-components'

import { FontSize, Black } from "../../variables"

const Textarea = styled.textarea`
	${FontSize.sml}
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	min-height: 105px;
	display: block;
	outline: none;
	border: 3px solid ${props => props.theme ? props.theme : Black.Primary};
	background: ${props => props.bg ? props.bg : "transparent"};
	color: ${Black.Primary};
	padding: 15px;
	font-family: inherit;
	&:-webkit-autofill,
	&:-webkit-autofill:hover, 
	&:-webkit-autofill:focus {
	  border: 1px solid ${Black.primary};
	  -webkit-text-fill-color: ${Black.Primary};
	  -webkit-box-shadow: 0 0 0px 1000px ${props => props.bg ? props.bg : "transparent"} inset;
	  transition: background-color 5000s ease-in-out 0s;
	}
	&[disabled] {
		pointer-events:none;
	}
`
export const TextareaField = ({ bg, name, placeholder, onChange, options, required, theme, value }) => {
	return (
		
		<Textarea 
			bg={ bg }
			theme={ theme }
			placeholder={ placeholder } 
			name={ name }  
			onChange={ onChange } 
			required={ required }>{ value }</Textarea>
		
	)
}
	