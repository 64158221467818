const size = {
	sml: '560px',
	med: '768px',
	lrg: '992px',
	xlrg: '1200px',
	xxlrg: '1400px',
}
export const BreakPoint = {
	sml: `(min-width: ${size.sml})`,
	med: `(min-width: ${size.med})`,
	lrg: `(min-width: ${size.lrg})`,
	xlrg: `(min-width: ${size.xlrg})`,
	xxlrg: `(min-width: ${size.xxlrg})`,
	ie: `@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)`
};