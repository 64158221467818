import React, { useState } from "react"
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

import { TextBox, Image, Ring } from '../../partials'
import { BreakPoint, Colours } from "../../variables/"

const TileWrapper = styled.div`
	position: relative;
	overflow: hidden;
	flex:0 0 100%;
	@media ${BreakPoint.sml} { 
		flex:0 0 50%;
	}
	@media ${BreakPoint.lrg} { 
		flex:0 0 calc(100%/3);
	}
	@media ${BreakPoint.xxlrg} { 
		flex:0 0 25%;
	}
`
const TileTrigger = styled.span`
	font-size: 2.5rem;
	padding: 15px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: ${props => props.bg ? props.bg : "#eee"};
	@media ${BreakPoint.sml} { 
		display: none;
	}
`
const TileRing = styled.span`
	color: #000!important;
`
const TileImage = styled.div`
	pointer-events: none;
`
const TileOverlay = styled(animated.div)`
	position: absolute;
	transform: translate3d(0,-100vh,0);
	-webkit-transition: top 0.5s;
	transition: top 0.5s;
    animation-fill-mode: forwards;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: ${props => props.bg ? props.bg : "#eee"};
	color: #fff;
	display: flex;
	align-items: center;
	padding: 60px;
	pointer-events: none;

`

export const TeamTile = (props) => {

	const [isToggled, setToggle] = useState(false)
	
	const transitionOptions = [
		'translate3d(0vw,-100vh,0)',
		'translate3d(0vw,100vh,0)',
		'translate3d(-100vw,0vh,0)',
		'translate3d(100vw,0vh,0)',
	]
	const randomTransition = Math.floor(Math.random()*transitionOptions.length)
	const bgAnimate = useSpring({
		transform: 
			isToggled ? 'translate3d(0vw,0vh,0)' : transitionOptions[randomTransition]
	})
	
	let OverlayColour = Colours.filter(theme => ('tabriz-blue').includes(theme.slug))
	OverlayColour = OverlayColour[0].primary

	const LastColour = Colours.filter(colour => ('mandarin').includes(colour.slug));

	return (
		<TileWrapper
	    	role="link"
	    	tabIndex={0}
	    	onMouseOver={() => setToggle(true)}
	    	onFocus={() => setToggle(true)}
	    	onMouseOut={() => setToggle(false)}
	    	onBlur={() => setToggle(false)}>
			<TileTrigger 
				className={( isToggled ? `icon-close` : `icon-plus`)}
				bg={ OverlayColour }
				role="link"
				tabIndex={0}
				onClick={() => setToggle(!isToggled)}
				onKeyDown={() => setToggle(!isToggled)}/>
	    	<TileOverlay bg={ OverlayColour } style={ bgAnimate }>
	    		<TextBox noPadding>
	    			<h3>
	    				{ props.data.name }
	    			</h3>
					<p>{ props.data.description.description }</p>
				</TextBox>
	    	</TileOverlay>
			{ props.data.image &&
				<TileImage>
					<Image imageData={ props.data.image } />
				</TileImage>
			}

		</TileWrapper>
	)
}