import React from "react"
import styled from 'styled-components'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { Link } from "gatsby"

import { Ring } from "./"

const StyledGatsbyLink = styled(Link)`
	color: ${props => props.colour ? props.colour : "inherit"};
    &:hover {
		border-color: ${props => props.colour ? props.colour+"!important;" : "inherit"};
	}
`
const StyledHyperlink = styled.a`
	color: ${props => props.colour ? props.colour : "inherit"};
    &:hover {
		border-color: ${props => props.colour ? props.colour+"!important;" : "inherit"};
	}
`

export const ContentfulRichText = ({ raw, colour }) => {

	const locale = 'en-GB'

	const options = {
		renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
		renderMark: {
		  [MARKS.BOLD]: (text) => <b className="bold">{text}</b>,
		  [MARKS.ITALIC]: (text) => <i className="italic">{text}</i>,
		  [MARKS.UNDERLINE]: (text) => <u className="underline">{text}</u>,
		  [MARKS.CODE]: (text) => <code className="code">{text}</code>,
		},
		renderNode: {
			[BLOCKS.HEADING_1]: (node, children) => {
				return <h1>{children}</h1>
			},
			[BLOCKS.HEADING_2]: (node, children) => {
				return <h2>{children}</h2>
			},
			[BLOCKS.HEADING_3]: (node, children) => {
				return <h3>{children}</h3>
			},
			[BLOCKS.HEADING_4]: (node, children) => {
				return <h4>{children}</h4>
			},
			[BLOCKS.HEADING_5]: (node, children) => {
				return <h5>{children}</h5>
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <h6>{children}</h6>
			},
			[BLOCKS.HEADING_6]: (node, children) => {
				return <h6>{children}</h6>
			},
	
			[BLOCKS.UL_LIST]: (node, children) => (
			  <ul>{children}</ul>
			),
			[BLOCKS.OL_LIST]: (node, children) => (
			  <ol>{children}</ol>
			),
			[BLOCKS.LIST_ITEM]: (node, children) => (
			  <li>{node.content[0].content[0].value}</li>
			),
	
			[BLOCKS.HR]: (node, children) => {
				return <hr/>
			},
			[BLOCKS.QUOTE]: (node, children) => {
				return <blockquote>{children}</blockquote>
			},

			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const fields = node.data.target.fields || false
				if (fields) {
					return <img alt={ fields.title[locale] } className="lazyload" src={ fields.file[locale].url } />
				}
			},

			[INLINES.HYPERLINK]: (node, children) => {
				return <StyledHyperlink colour={colour} href={node.data.uri} target="_blank" rel="noopener noreferrer">{children}</StyledHyperlink>
			},
			[INLINES.ENTRY_HYPERLINK]: (node, children) => {
				const fields = node.data.target.fields || false
				if (fields) {
					return <StyledGatsbyLink colour={colour} to={`/blog/${fields.slug[locale]}/`}>{children}</StyledGatsbyLink>
				} else {
					return <span>{children}</span>
				}
			},
			[BLOCKS.EMBEDDED_ENTRY]: node => {
				return null
			},

			[INLINES.EMBEDDED_ENTRY]: (node) => {
				return null
			},
			[INLINES.ASSET_HYPERLINK]: (node) => {
				return null
			},
		},
	}

	return raw && renderRichText(raw, options)
}
