import React from "react"
import styled from 'styled-components'

import { ContentfulRichText, TextBox } from "../partials/"
import { BreakPoint } from '../variables/'
import { Restrict, TextContent } from '../styles/'

const Wrapper = styled(Restrict)`
	
`
const Group = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	margin: 20px 0px;
	@media ${BreakPoint.med} { 
		flex-direction: row;
		margin: 40px 0;
	}
`
const Card = styled.div`
	margin: 20px 35px;
	@media ${BreakPoint.med} { 
		width:calc(50% - 120px);
		margin: 20px 60px;
	}
`

export const CardBuilder = ({ cards, colour }) => {
	
	return (
		<Wrapper>
			<Group>
				{ cards.map((card, i) => {
					return (
						<Card key={i}>
							<TextContent>
								<ContentfulRichText raw={ card.text } colour={ colour }/>
							</TextContent>
						</Card>
					)
				})}
			</Group>
		</Wrapper>
	)
}