import styled from 'styled-components'
import { BreakPoint } from "../variables/"

export const Element = styled.div`
	margin-bottom: 20px;
	text-align: ${props => props.align ? props.align : "inherit"};
	&:last-Child {
		margin-bottom: 0;
	}
	@media ${BreakPoint.med} { 
		margin-bottom: 40px;
	}
`