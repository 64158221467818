import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'

import { BreakPoint } from '../variables'

const SplitWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: calc(100vw/3);
	background: ${props => props.bg ? props.bg : "none"};
	@media ${BreakPoint.med} { 
		flex-direction: ${props => props.flip ? "row-reverse" : "row"};
		min-height: 
			${props => props.fill ? "calc(100vh - 37.5vw)" : 
			(props.fitText ? "auto" : "calc(100vw/3)")};
	}
	@media ${BreakPoint.lrg} { 
		min-height: 
			${props => props.fill ? "calc(100vh - 25vw)" : 
			(props.fitText ? "auto" : "calc(100vw/3)")};
	}
	>div {
		@media ${BreakPoint.med} { 
			width: 50%;
		}
	}
`

export const Split = (props) => {

	return (
		<SplitWrapper 
			bg={ props.bg }
			flip={ props.flip } 
			fill={ props.fill } 
			fitText={ props.fitText }>
			{ props.children }
		</SplitWrapper>
	)
	}

Split.propTypes = {
  children: PropTypes.node.isRequired,
}