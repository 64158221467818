export const Colours = [
	{
	    slug: "black",
	    primary: "#1d1d1d",
	    secondary: "#fff"
    },
    {
	    slug: "grey",
	    primary: "#eee",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "factory-yellow",
	    primary: "#FEDD00",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "citrine",
	    primary: "#F2A900",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "mandarin",
	    primary: "#FF6900",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "bright-red",
	    primary: "#EF3340",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "vermillion",
	    primary: "#BF0D3E",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "fuschia-pink",
	    primary: "#DA1884",
	    secondary: "#fff"
    },
    {
	    slug: "purple",
	    primary: "#753BBD",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "royal-blue",
	    primary: "#685BC7",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "adriatic",
	    primary: "#418FDE",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "tabriz-blue",
	    primary: "#0092BC",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "new-blue",
	    primary: "#ABCAE9",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "turquoise",
	    primary: "#2DCCD3",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "emerald",
	    primary: "#007A53",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "lockwood-green",
	    primary: "#007A53",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "green",
	    primary: "#97D700",
	    secondary: "#1d1d1d"
    },
    {
	    slug: "autumn",
	    primary: "#C25518",
	    secondary: "#1d1d1d"
    },
]

export const Black = { 
	primary: "#1d1d1d", 
	secondary: "#fff" }
export const White = { 
	primary: "#ffffff", 
	secondary: "#1d1d1d" }
export const Grey = { 
	primary: "#eee", 
	secondary: "#1d1d1d" }