import React, { useEffect } from "react"
import { useSpring, animated } from 'react-spring'
import styled from "styled-components"

import { Link } from "gatsby"

import { BreakPoint, FontSize } from "../../variables/"
import { Image } from "../../partials"

const TileRowWrapper = styled(animated.div)`
	overflow: scroll;
	width: 100vw;
	&::-webkit-scrollbar {
    	display: none;
	}
`
const TileGroup = styled.div`
	width: ${props => props.width ? props.width : "auto"};
`

const Tile = styled(Link)`
	width: ${props => props.width ? props.width : "auto"};
	position: relative;
	float: left;
	text-align: center;
	&:hover {
		div {
			opacity: 1;
		}
	}
`
const Title = styled.h3`
	font-size: 2rem;
	margin: 0;
	@media ${BreakPoint.xlrg} { 
		font-size: 2.5rem;
		margin: 0 0 5px;
	}
`
const Sector = styled.h4`
	font-size: 1.8rem;
	font-weight: 400;
	margin: 0 0 5px;
	@media ${BreakPoint.xlrg} { 
		font-size: 2rem;
		margin: 0 0 15px;
	}
`
const Scope = styled.p`
	${FontSize.xsml}
	font-weight: 400;
	margin: 0;
`
const Overlay = styled.div`
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	background: rgba(0,0,0,0.8);
	color: white;
	display: flex;
	overflow: auto;
	&:hover {
		opacity: 1;
	}
`
const OverlayContent = styled.div`
	width: 100%;
	text-align: center;
	align-self: center;
	padding: 15px;
`

export const ClientSliderRow = (props) => {
	
	const [animate, setAnimate] = useSpring(() => ({scrollLeft: 0}))

	function scrollLeft() {
		
		const scroller = document.getElementById(props.id)
		const tiles = document.getElementById(props.id+'-tiles')
		
		if(scroller.scrollLeft === 0) {
// 			console.log('start')
			setAnimate({ scrollLeft: tiles.offsetWidth, config: { duration: 0 } })
		} else {
			setAnimate({ scrollLeft: scroller.scrollLeft - (2*props.speed), config: { duration: 0 } })
		}
	}
	
	function scrollRight() {
		
		const scroller = document.getElementById(props.id)
		const tiles = document.getElementById(props.id+'-tiles')
		
		if(scroller.scrollLeft === tiles.offsetWidth - scroller.offsetWidth) {
// 			console.log('end')
			setAnimate({ scrollLeft: 0, config: { duration: 0 } })
		} else {
			setAnimate({ scrollLeft: scroller.scrollLeft + (2*props.speed), config: { duration: 0 } })
		}
	}
	
	function swipeLeft() {
		
		const scroller = document.getElementById(props.id)
		const tiles = document.getElementById(props.id+'-tiles')
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 300)
		
		if(scroller.scrollLeft === 0) {
// 			console.log('start')
			setAnimate({ scrollLeft: tiles.offsetWidth - (w/(props.tiles/2)), config: { duration: 0 } })
				
		} else {
			setAnimate({ scrollLeft: scroller.scrollLeft - (w/(props.tiles/2)), config: { duration: 500 } })
		}
	}
	
	function swipeRight() {
		
		const scroller = document.getElementById(props.id)
		const tiles = document.getElementById(props.id+'-tiles')
		const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 300)
		
		if(scroller.scrollLeft === tiles.offsetWidth - scroller.offsetWidth) {
// 			console.log('end')
			setAnimate({ scrollLeft: 0 + (w/(props.tiles/2)), config: { duration: 0 } })
		} else {
			setAnimate({ scrollLeft: scroller.scrollLeft + (w/(props.tiles/2)), config: { duration: 500 } })
		}
	}
	
	function wheel(e) {

		if (e.deltaY < 0) {
// 			console.log('up')
			scrollLeft();
		}
		else if (e.deltaY > 0) {
// 			console.log('down')
			scrollRight();
		}
	}
	
	let touchstartX = 0
	let touchstartY = 0
	let touchendX = 0
	let touchendY = 0
	
	function handleGesture() {

		let swipe_left = touchendX <= touchstartX;
		let swipe_right = touchendX >= touchstartX;
		let swipe_up = touchendY <= touchstartY;
		let swipe_down = touchendY >= touchstartY;
		let no_y_scroll = touchendY === touchstartY;
	    
	    if (no_y_scroll) {
	        return false;
	    } else if (swipe_right) {
		   swipeRight();
		} else if (swipe_left) {
	        swipeLeft();
	    } else if (swipe_up) {
		   swipeLeft();
		} else if (swipe_down) {
	        swipeRight();
	    }  
	}
	
	useEffect(() => {	
		window.addEventListener("wheel", wheel)
		
		window.addEventListener('touchstart', function(event) {
		    touchstartX = event.changedTouches[0].screenX
		    touchstartY = event.changedTouches[0].screenY
		}, false)
		
		window.addEventListener('touchend', function(event) {
		    touchendX = event.changedTouches[0].screenX
		    touchendY = event.changedTouches[0].screenY
		    handleGesture()
		}, false)
		
		const shiftTiles = (document.getElementById(props.id+'-tiles').offsetWidth/(props.data.length+props.tiles))*props.shift
		setAnimate({ scrollLeft: shiftTiles, config: { duration: 0 } })
	})

	return (
		
		<TileRowWrapper id={ props.id } scrollLeft={animate.scrollLeft}>
			<TileGroup 
				id={ props.id+'-tiles' }
				width={((100/props.tiles)*(props.data.length+props.tiles))+'%'}>
				
				{props.data.map(client => {
				    const clientData = client.node
				    return ( 
					    <Tile 
							key={props.id+'-'+clientData.slug} 
							to={`/client/${clientData.slug}`}
							width={ (100/(props.data.length+props.tiles))+'%' }>
							{ props.overlay &&
								<Overlay>
									<OverlayContent>
										<Title>{ clientData.title }</Title>
										<Sector>{ clientData.sector }</Sector>
										{ clientData.overview &&
											<Scope>{ clientData.overview }</Scope>
										}
									</OverlayContent>
								</Overlay>
							}
							{ clientData.blockOneImage ?
								<Image imageData={clientData.blockOneImage.gatsbyImageData} alt={`Click to find out more about our work with `+clientData.title}/>
							:
								<Title>{ clientData.title }</Title>
							}
						</Tile>
				    )
				    
				  })}
				  {props.data.slice(0, props.tiles).map(client => {
				    const clientData = client.node
				    return ( 
					    <Tile 
							key={props.id+'-'+clientData.slug+'-clone'} 
							to={`/client/${clientData.slug}`}
							width={ (100/(props.data.length+props.tiles))+'%' }>
							{ props.overlay &&
								<Overlay>
									<OverlayContent>
										<Title>{ clientData.title }</Title>
										{ clientData.overview &&
											<Scope>{ clientData.overview }</Scope>
										}
									</OverlayContent>
								</Overlay>
							}
							{ clientData.blockOneImage ?
								<Image imageData={clientData.blockOneImage.gatsbyImageData} alt={`Click to find out more about our work with `+clientData.title}/>
							:
								<Title>{ clientData.title }</Title>
							}
						</Tile>
				    )
				    
				  })}
				  
			</TileGroup>
		</TileRowWrapper>
	)
}