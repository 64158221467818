import styled from 'styled-components'
import { FontSize } from '../variables/'

export const TextContent = styled.div`
	* {
		margin: 0;
	}
	h1,h2,h3,h4,h5,h6 {
		span {
			font-size: 1rem;
			margin: 0 0.2rem!important;
		}
	}
	h1 {
		margin-bottom: 40px;
	}
	h2 {
		margin-bottom: 30px;
	}
	h3,h4,h5,h6 {
		margin-bottom: 20px;
	}
	h1 {
		${FontSize.xlrg}
	}
	h2 {
		${FontSize.lrg}
	}
	h3, h4 {
		${FontSize.med}
	}
	h5, h6, ul, ol, p, a {
		text-decoration: none;
		margin-bottom: 20px;
		${FontSize.sml}
		line-height: 140%;
		letter-spacing: 0.05rem;
	}
	ul, ol {
		padding-left: 20px;
	}
	ul {
		list-style: disc;
	}
	li {
		margin-bottom: 5px;
	}
	a {
		border-bottom: 3px solid transparent;
		&:hover {
			border-color: inherit;
		}
	}
	hr {
		margin: 20px 0;
	}
	img {
		margin-bottom: 20px;
		max-width: 100%;
	}
	blockquote {
		display: inline-block;
		background: rgba(238, 238, 238, 0.4);
		border-left: 10px solid #eee;
		margin-bottom: 20px;
		padding: 10px 20px;
		p {
			display: inline;
		}
	}
	.fade {
		opacity: 0.5;
	}
	> *:last-child {
		margin: 0!important;
	}
`
